/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

// Material Kit 2 PRO React components
import SimpleReviewCard from "examples/Cards/ReviewCards/SimpleReviewCard";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import team2 from "assets/images/jordon.jpg";
import team3 from "assets/images/trudy.jpeg";
import team4 from "assets/images/alicia.jpeg";

function TestimonialsThree() {
  return (
    <MKBox position="relative" variant="gradient" bgColor="dark" py={2} mx={-2}>
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.2}
        display={{ xs: "none", md: "block" }}
      />
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{ pt: 8, pb: 5, position: "relative", zIndex: 3 }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <MKBadge
              badgeContent="testimonials"
              variant="contained"
              color="white"
              size="sm"
              container
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" color="white" mb={1}>
              Others That Used The App
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 10, mb: 6 }}>
          <Grid item xs={12} md={4}>
            <SimpleReviewCard
              image={team2}
              name="Jordon Levy"
              username=""
              review="The Island Destinations App has proven to be a great help to me when I need to find Jamaica's hidden gems. Easy to use and it helped me to discover some new places that i honestly didn't even knew existed here."
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
            <SimpleReviewCard
              image={team3}
              name="Trudy-Ann Smith"
              username=""
              review="I booked my camping trip through the app n it was easy, simple n quick. Only thing I needed to do next was turn up n enjoy the time! Travis made the whole experience phenomenal and I would recommend 10/10"
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
            <SimpleReviewCard
              image={team4}
              name="Alicia Massop"
              username=""
              review="The island destinations app has proven to be a great help to me ….It is the ONLY app in Jamaica that i can rely on for a good destination based on the respective parish i wish to travel…..Also the activities are categorized for example if you want to find hidden gems by rivers , food spot you name its there on the App…..Not to mention how easy the app is to navigate through. For your next get away and you are unsure where to go visit the app"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TestimonialsThree;
