/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/CenteredFooter";

// Routes
// import routes from "routes";
import footerRoutes from "footer.routes";

function Privacy() {
  return (
    <>
      {/* <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "dark",
        }}
        sticky
      /> */}
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Privacy & Policy
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: Sept 07 2022
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body0" color="dark">
                    {`This Privacy Policy describes Our policies and procedures on the collection,
                    use and disclosure of Your information when You use the Service and tells You about 
                    Your privacy rights and how the law protects You. We use Your Personal data to provide 
                    and improve the Service. By using the Service, You agree to the collection and use of 
                    information in accordance with this Privacy Policy.`}
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Interpretation and Definitions
                  </MKTypography>
                  <MKTypography variant="body0" color="dark">
                    {`Interpretation
The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.

Definitions
For the purposes of this Privacy Policy:`}
                  </MKTypography>
                  <MKBox component="ol" my={3} ml={6}>
                    <MKBox component="li">{`Account means a unique account created for You to access our Service or parts of our Service.`}</MKBox>
                    <MKBox component="li">{`Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.`}</MKBox>
                    <MKBox component="li">{`Application means the software program provided by the Company downloaded by You on any electronic device, named Island Destinations Jamaica.`}</MKBox>
                    <MKBox component="li">{`Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Travel App Jamaica Limited, 2, Orange Close, Spanish Town, St. Catherine, Jamaica.`}</MKBox>
                    <MKBox component="li">{`Country refers to:  Jamaica.`}</MKBox>
                    <MKBox component="li">{`Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.`}</MKBox>
                    <MKBox component="li">{`Personal Data is any information that relates to an identified or identifiable individual.`}</MKBox>
                    <MKBox component="li">{`Service refers to the Application.`}</MKBox>
                    <MKBox component="li">{`Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.`}</MKBox>
                    <MKBox component="li">{`Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).`}</MKBox>
                    <MKBox component="li">{`You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.`}</MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Collecting and Using Your Personal Data
                  </MKTypography>
                  <MKTypography variant="body0" color="dark" fontWeight="regular">
                    {`Personal Data

While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
`}
                  </MKTypography>
                  <MKBox component="ol" my={3} ml={6}>
                    <MKBox component="li">{`Email address`}</MKBox>
                    <MKBox component="li">{`First name and last name`}</MKBox>
                    <MKBox component="li">{`Phone number`}</MKBox>
                    <MKBox component="li">{`Address, State, Province, ZIP/Postal code, City`}</MKBox>
                    <MKBox component="li">{`Usage Data`}</MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>{`Usage Data`}</MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    {`Usage Data is collected automatically when using the Service.`}
                  </MKTypography>
                  <MKTypography variant="body0" color="dark" fontWeight="regular">
                    {`Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
`}
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    {`Information Collected while Using the Application`}
                  </MKTypography>
                  <MKTypography variant="body0" color="dark" fontWeight="regular">
                    {`While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:`}
                  </MKTypography>
                  <MKBox component="ol" my={3} ml={6}>
                    <MKBox component="li">Information regarding your location</MKBox>
                    <MKBox component="li">{`Pictures and other information from your Device's camera and photo library`}</MKBox>
                  </MKBox>

                  <MKTypography variant="body0" color="dark">
                    {`We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.

                    You can enable or disable access to this information at any time, through Your Device settings.

                    Use of Your Personal Data

                    The Company may use Personal Data for the following purposes:`}
                  </MKTypography>

                  <MKBox component="ol" my={3} ml={6}>
                    <MKBox component="li">{`To provide and maintain our Service, including to monitor the usage of our Service.`}</MKBox>
                    <MKBox component="li">{`To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.`}</MKBox>
                    <MKBox component="li">{`For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.`}</MKBox>
                    <MKBox component="li">{`To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.`}</MKBox>
                    <MKBox component="li">{`To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.`}</MKBox>
                    <MKBox component="li">{`To manage Your requests: To attend and manage Your requests to Us.`}</MKBox>
                    <MKBox component="li">{`For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.`}</MKBox>
                    <MKBox component="li">{`For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.`}</MKBox>
                  </MKBox>
                  <MKTypography variant="body0" color="dark">
                    We may share Your personal information in the following situations:
                  </MKTypography>
                  <MKBox component="ol" my={3} ml={6}>
                    <MKBox component="li">{`With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.`}</MKBox>
                    <MKBox component="li">{`For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.`}</MKBox>
                    <MKBox component="li">{`With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.`}</MKBox>
                    <MKBox component="li">{`With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.`}</MKBox>
                    <MKBox component="li">{`With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.`}</MKBox>
                    <MKBox component="li">{`With Your consent: We may disclose Your personal information for any other purpose with Your consent.`}</MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    {`Retention of Your Personal Data`}
                  </MKTypography>
                  <MKTypography variant="body0" color="dark">
                    {`The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.

The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.`}
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    {`Transfer of Your Personal Data`}
                  </MKTypography>
                  <MKTypography variant="body0" color="dark">
                    {`Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.

Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.

The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
`}
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    {`Disclosure of Your Personal Data`}
                  </MKTypography>
                  <MKTypography variant="h6" mt={6} mb={3}>
                    {`Business Transactions`}
                  </MKTypography>

                  <MKTypography variant="body0" color="dark">
                    {`If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.`}
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    {`Law enforcement`}
                  </MKTypography>
                  <MKTypography variant="body0" color="dark">
                    {`Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).`}
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    {`Other legal requirements`}
                  </MKTypography>
                  <MKTypography variant="body0" color="dark">
                    {`The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:`}
                  </MKTypography>
                  <MKBox component="ol" my={3} ml={6}>
                    <MKBox component="li">{`Comply with a legal obligation`}</MKBox>
                    <MKBox component="li">{`Protect and defend the rights or property of the Company`}</MKBox>
                    <MKBox component="li">{`Prevent or investigate possible wrongdoing in connection with the Service`}</MKBox>
                    <MKBox component="li">{`Protect the personal safety of Users of the Service or the public`}</MKBox>
                    <MKBox component="li">{`rotect against legal liability`}</MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    {`Security of Your Personal Data`}
                  </MKTypography>
                  <MKTypography variant="body0" color="dark">
                    {`The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.`}
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Children&apos;s Privacy
                  </MKTypography>
                  <MKTypography variant="body0" color="dark" fontWeight="regular">
                    {`Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.

If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
`}
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    CONTACT US
                  </MKTypography>
                  <MKTypography variant="body0" color="dark" fontWeight="regular">
                    {`In order to resolve a complaint regarding the Mobile Application or to receive further information regarding use of the Mobile Application, please contact us at:`}
                    <MKTypography variant="body0" color="dark" fontWeight="bold">
                      <br />
                      <br />
                      {`TRAVEL APP JAMAICA LIMITED`}
                    </MKTypography>
                    <MKTypography variant="body2" color="dark" fontWeight="bold">
                      {`2 Orange Close, Spanish Town, St. Catherine`}
                    </MKTypography>
                    <MKTypography variant="body2" color="dark" fontWeight="bold">
                      {`(876) 451-6046`}
                    </MKTypography>
                    <MKTypography variant="body2" color="dark" fontWeight="bold">
                      {`islanddestinationsja@gmail.com`}
                    </MKTypography>
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
